import toast from "react-hot-toast";
import httpService from "../config/authAxios";

export async function uploadFiles(file, ipfs = false) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  const res = await httpService.post(
    `/file/upload/single?ipfs=${ipfs}`,
    formData
  );
  const data = await res?.data;
  return data;
}

export async function uploadMultipleFiles(files) {
  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append(`files`, file, file.name);
  });
  const res = await httpService.post(`/file/upload/multiple`, formData);
  const data = await res?.data;
  return data;
}

export async function uploadeditFiles(file) {
  console.log("check file of check,", file);
  const formData = new FormData();
  // Store form name as "file" with file data
  formData.append("file", file, file.name);
  const { data: response } = await httpService.post(
    "/file/upload/single",
    formData
  );
  console.log("check AK", response);
  return response;
}
