import { Typography } from "@material-ui/core";
import { Badge, Box, Button, Card, Divider, IconButton, Modal, Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from 'react-redux';
import { Notifications as NotificationsIcon } from '@material-ui/icons';

import { allUnseenNotificationCountAPI } from "../../redux/notification/allUnseenNotificationCount";
import Style from "../../styles/notification.module.scss";
import AllNotification from "./AllNotification";
import UnSeenNotification from "./UnSeenNotification";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MarkAsReadReducerAPI } from "../../redux/notification/allread";



const Notification = ({ open, setOpen,setNavMenu}) => {
  const dispatch = useDispatch()

  const handleClose = (setNavMenu) => {
    setOpen(false);
    setNavMenu(true);
  };

  useEffect(()=>{
    dispatch(MarkAsReadReducerAPI())
  })

  return (
    <Modal open={open} onClose={handleClose} className={Style.main}>
      <Card className={Style.main_}>
        <Badge color="primary">
          <Stack direction='row' alignItems='center'>
          <IconButton onClick={() => handleClose(setNavMenu)}>
            <ArrowBackIcon />
          </IconButton>
            <Typography variant="h5">Notifications</Typography>
          </Stack>
        </Badge>
        <AllNotification />       
      </Card>
    </Modal>
  );
};
export default Notification;
