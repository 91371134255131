import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HttpService from "../../config/authAxios"

const initialState = { loading: false, result: null, error: null, message: "" }

export const clearSingleCartAPI = createAsyncThunk(
    'clear-single-cart',
    async (data, { rejectWithValue }) => {
        try {
            const response = await HttpService.delete(`/cart/clbs/clear/${data}`)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'clear-single-cart',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(clearSingleCartAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(clearSingleCartAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(clearSingleCartAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const clearSingleCartReducer = counterSlice.reducer

export default clearSingleCartReducer

