import React, { useEffect } from "react";
import ReactSlider from "../../common/ReactSlider";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllGalleryAPI } from "../../redux/gallery/getAllGallery";
import { Box, Container, Typography } from "@mui/material";
import DividerUI from "../../ui/DividerUI";
import LoaderUI from "../../ui/LoaderUI";
import { Button, Stack } from "@mui/material";

export default function Gallery() {
  const getAllGallerySelector = useSelector((state) => state?.getAllGallery);
  const { result, loading } = getAllGallerySelector;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllGalleryAPI());
  }, []);

  return (
    <>
      <Box sx={{ my: 8 }}>
        <Typography align="center" variant="h4">
          Gallery
        </Typography>
        <Stack
          direction="row"
          alignItems="baseline"
          justifyContent="right"
          spacing={1}
          sx={{ flex: 1 }}
        >
          <Button
            sx={{ color: "primary.main", marginLeft: "auto" }}
            onClick={() => navigate("/gallery")}
          >
            View all
          </Button>
        </Stack>
        <DividerUI />
        <Container>
          {loading ? (
            <LoaderUI />
          ) : (
            <ReactSlider autoplay={true}>
              {result?.map((item, idx) => {
                return (
                  <>
                    <Box sx={{ p: 2, height: 300 }} onClick={() => navigate("/gallery")}>
                      <img src={item?.image} alt="gallery image" width={"100%"} height={"100%"} />
                    </Box>
                  </>
                );
              })}
            </ReactSlider>
          )}
        </Container>
      </Box>
    </>
  );
}
