import { createContext, useContext, useState } from "react";
const GlobalContext = createContext();
// Create a provider component
export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    globalVar: "value",
  });

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};
// Create a custom hook to access the context
export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return context;
};
