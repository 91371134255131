import { AppBar, Box, Fab, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header/Header";
import Style from "../../styles/landing.module.scss";
import Gallery from "./Gallery";
import HotCellCLBs from "./HotCellCLBs";
import HotCollection from "./HotCollection";
import Landing from "./Landing";
import LiveCLBSection from "./LiveCLB";
import TopBids from "./TopBids";
import TopCollectors from "./TopCollectors";
import TopSeller from "./TopSeller";
import Work from "./Work";
import Footer from "../../common/footer";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { useNavigate } from "react-router-dom";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";

const Home = () => {
  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);
  const navigate = useNavigate();
  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { error } = getCurrentUserSelector;
  const handleRaiseTicket = () => {
    if (error) return setUserNotLoggedIn(true);
    navigate("/ticket/new");
  };
  return (
    <>
      <Box className={Style.main}>
        <Header />
        <Landing />
        <Box className={Style.others}>
          <Work />
          <HotCollection /> {/* Need to rename as Latest Collection*/}
          <HotCellCLBs />
          <LiveCLBSection />
          {/* <TopSeller /> */}
          <TopCollectors />
          <TopBids />
          <Gallery />
          <Footer />
          <Tooltip title="Raise Ticket">
            <Fab
              onClick={handleRaiseTicket}
              sx={{ position: "fixed", bottom: "20px", right: "20px" }}
            >
              <RateReviewIcon
                sx={{ width: "30px !important", height: "30px !important" }}
              />
            </Fab>
          </Tooltip>
        </Box>
      </Box>
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
    </>
  );
};
export default Home;
