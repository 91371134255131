import React, { useEffect } from "react"
import Style from "../termsCondition/terms.module.css"
import Header from "../../header/Header"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { fetchPrivacyPolicy } from "../../../redux/privacyPolicy/privacy"


const Privacy = () => {
    const dispatch = useDispatch();

    const privacyPolicy = useSelector(state => state.policy?.result?.privacyPolicy || {});

    const title = privacyPolicy?.title || "Default Title";
    const desc = privacyPolicy?.desc || "No description available.";

    useEffect(() => {
        window.scroll(0, 0);
        dispatch(fetchPrivacyPolicy())
    }, [dispatch]);

    return (
        <Box className={Style.main}>
            <Header />
            <div className={Style.container}>
                <div className={Style.contained}>
                    <h1>{title}</h1>
                    <hr />
                    <p style={{padding: '50px 80px', width: '100%', wordWrap: 'break-word', overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                        {desc}
                    </p>
                </div>
            </div>
        </Box>
    )
}
export default Privacy