import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";
import { changeCLBsFavStatusAPI } from "../../redux/favorite/changeCLBsFavStatus";
import { getCreatedCLbsAPI } from "../../redux/user/getCreatedCLbs";
import Style from "../../styles/topBid.module.scss";
import LoaderUI from "../../ui/LoaderUI";
import moment from "moment";

const CollectedCLBs = () => {
  const getCreatedCLbsSelector = useSelector((state) => state?.getCreatedCLbs);
  const { result, loading } = getCreatedCLbsSelector;

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { error: userError } = getCurrentUserSelector;

  const changeCLBsFavStatusSelector = useSelector(
    (state) => state?.changeCLBsFavStatus
  );
  const { message, error } = changeCLBsFavStatusSelector;

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [CLBFilter, setCLBFilter] = useState("All");
  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);

  const handleFavirote = async (fav, clbId) => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    await dispatch(changeCLBsFavStatusAPI({ isFavorite: fav, clbId }));
    await dispatch(getCreatedCLbsAPI({ ownerId: id, filter: CLBFilter }));
  };

  const handleFilterCollectCLBs = (filter) => {
    setCLBFilter(filter);
    dispatch(getCreatedCLbsAPI({ ownerId: id, filter }));
  };

  useEffect(() => {
    dispatch(getCreatedCLbsAPI({ ownerId: id, filter: CLBFilter }));
  }, []);

  return (
    <>
      {loading ? (
        <LoaderUI />
      ) : (
        <Grid container spacing={3} sx={{ my: 4 }}>
          {result?.map((item, idx) => {
            return (
              <Grid item xs={12} sm={6} md={3} lg={3} key={idx}>
                <Paper className="SingelCLBBox">
                  <Stack
                    alignItems="center"
                    justifyContent="flex-end"
                    direction="row"
                    zIndex={"99"}
                    position={"relative"}
                  >
                    {item?.isBidAble && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        flexGrow={2}
                        spacing={1}
                        sx={{ px: 2 }}
                      >
                        <Typography>Ending at :</Typography>
                        <p
                          className={
                            moment(item?.endDate).isAfter(moment())
                              ? "text-green-400"
                              : "text-red-500"
                          }
                        >
                          {moment(item?.endDate).format("DD MMM YYYY")}
                        </p>
                      </Stack>
                    )}

                    {item?.favorites && item?.favorites !== undefined ? (
                      <IconButton
                        onClick={() => handleFavirote(false, item?._id)}
                        style={{ cursor: "default", pointerEvents: "auto" }} // Set arrow cursor and disable hover effect
                      >
                        <FavoriteIcon sx={{ color: "primary.main" }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleFavirote(true, item?._id)}
                        style={{ cursor: "default", pointerEvents: "auto" }} // Set arrow cursor and disable hover effect
                      >
                        <FavoriteBorderIcon sx={{ color: "primary.main" }} />
                      </IconButton>
                    )}
                  </Stack>

                  <Box className="CLBBox">
                    {item?.type === "2d" && (
                      <img
                        alt=""
                        src={item?.files?.[0]}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                      />
                    )}
                    {item?.type === "3d" && (
                      <model-viewer
                        style={{ width: "100%", height: "auto", maxHeight: 250 }}
                        src={item?.files?.[0]}
                        ar-modes="webxr scene-viewer quick-look"
                        camera-controls
                        poster="poster.webp"
                        shadow-intensity="1"
                        disable
                      ></model-viewer>
                    )}
                  </Box>
                  <Stack sx={{ p: 2 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ mt: 2 }}
                      alignItems="center"
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                        }}
                        title={item?.title}
                      >
                        {item?.title}
                      </Typography>
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                        }}
                        className={Style?.price}
                      >
                        ₹ {item?.cost?.inr}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {item?.isBidAble ? (
                        <Button
                          className={"gradBtn"}
                          variant="contained"
                          style={{ cursor: "default", pointerEvents: "auto" }} // Set arrow cursor and disable hover effect
                        >
                          <Box>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#999999" }}
                            >
                              Start Bid
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              ₹ {item?.cost?.inr}
                            </Typography>
                          </Box>
                        </Button>
                      ) : (
                        <Button
                          className={"gradBtn"}
                          variant="contained"
                          style={{ cursor: "default", pointerEvents: "auto" }} // Set arrow cursor and disable hover effect
                        >
                          <Box>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#999999" }}
                            >
                              Purchase at
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              ₹ {item?.cost?.inr}
                            </Typography>
                          </Box>
                        </Button>
                      )}

                      {item?.status === "Approved" || item?.mint ? (
                        <Button
                          className={`${Style?.startBidBtn} gradBtn`}
                          variant="contained"
                          onClick={() => navigate(`/explore/${item._id}`)}
                          style={{ cursor: "default", pointerEvents: "auto" }} // Set arrow cursor and disable hover effect
                        >
                          View
                        </Button>
                      ) : (
                        <Typography variant="p" color="primary">
                          {item?.status}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      )}
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
    </>
  );
};

export default CollectedCLBs;
