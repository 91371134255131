import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";

const initialState = { loading: false, result: null, error: null, message: "" };

export const userFollowingAPI = createAsyncThunk(
  "user-following",
  async (data, { rejectWithValue }) => {
    // console.log("id===============>>>>>",data)
    try {
      const response = await HttpService.get(`/follow/current/following`, {
        params: data,
      });
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "user-following",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(userFollowingAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userFollowingAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(userFollowingAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const userFollowingReducer = counterSlice.reducer;

export default userFollowingReducer;
