import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedClBHistoryAPI } from '../../redux/clb/getSelectedClBHistory';
import { useEffect } from 'react';
import { Card, CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import NoDataFound from '../../common/NoDataFound';
import { dateYYMMDD } from '../../helper/date';

export default function ClbHistoryTable() {
    const getSelectedClBHistorySelector = useSelector(state => state?.getSelectedClBHistory)
    const { result, loading } = getSelectedClBHistorySelector

    const dispatch = useDispatch()
    const params = useParams()
    const clbId = params?.id

    const columns = [
        {
            field: 'owner',
            headerName: 'Owner',
            width: 100,
            align: 'left',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <Typography variant='subtitle2'>{params?.row?.ownerId?.name}</Typography>
            }
        },
        {
            field: 'from',
            headerName: 'From',
            width: 100,
            align: 'left',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <Typography variant='subtitle2'>{params?.row?.ownerId?.name}</Typography>
            }
        },
        {
            field: 'buyer',
            headerName: 'To',
            width: 100,
            align: 'left',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <Typography variant='subtitle2'>{params?.row?.buyerId?.name}</Typography>
            }
        },
        {
            field: 'price.inr',
            headerName: 'Price (INR)',
            width: 200,
            align: 'left',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <Typography variant='subtitle2'>{params?.row?.price?.inr}</Typography>
            }
        },
        {
            field: 'price.usd',
            headerName: 'Price (USD)',
            width: 200,
            align: 'left',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <Typography variant='subtitle2'>{params?.row?.price?.usd}</Typography>
            }
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 150,
            align: 'left',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <Typography variant='subtitle2'>{dateYYMMDD(params?.row?.date)}</Typography>
            }
        },

    ];


    useEffect(() => {
        dispatch(getSelectedClBHistoryAPI(clbId))
    }, [])


    return (
        <Card
            className='transCard'
            sx={{
                my: 2,
                height: 420,
                width: '100%',
                '& .super-app-theme--header': {
                    backgroundColor: '#000',
                },
            }}>
            <DataGrid
                  sx={{
                    color: '#fff',
                    '& .MuiTablePagination-toolbar': {
                        color: '#fff', // Color for the pagination numbers
                    },
                    '& .MuiTablePagination-actions .MuiIconButton-root': {
                        color: '#fff', // Color for the previous/next arrows
                    },
                    '& .MuiTablePagination-actions .MuiIconButton-root:hover': {
                        color: '#ddd', // Hover color for the arrows
                    },
                }}
                getRowId={(row) => row._id}
                getRowHeight={() => 'auto'}
                rows={result || []}
                columns={columns}
                pageSizeOptions={[25]}
                disableRowSelectionOnClick
                loading={loading && <CircularProgress />}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                }
                slots={{
                    noRowsOverlay: () => <NoDataFound />
                }}
            />
        </Card>
    );
}