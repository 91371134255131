import { Card, CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NoDataFound from "../../common/NoDataFound";
import { getClBBidDetailsAPI } from "../../redux/bid/getClBBidDetails";
import { dateYYMMDD } from "../../helper/date";

export default function BidTable() {
  const getClBBidDetailsSelector = useSelector(
    (state) => state?.getClBBidDetails
  );
  const { result, loading } = getClBBidDetailsSelector;

  const dispatch = useDispatch();
  const params = useParams();
  const clbId = params?.id;

  const columns = [
    {
      field: "created_at",
      headerName: "Date",
      width: 150,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Typography variant="subtitle2">
            {dateYYMMDD(params?.row?.created_at)}
          </Typography>
        );
      },
    },
    {
      field: "user",
      headerName: "Top Bidders",
      width: 200,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography variant="subtitle2" sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2, // Limit the number of lines to 3
            textOverflow: 'ellipsis',
          }}>
            {params?.row?.user?.name}
          </Typography>
        );
      },
    },
    {
      field: "price.inr",
      headerName: "Price (INR)",
      width: 200,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Typography variant="subtitle2">{params?.row?.price?.inr}</Typography>
        );
      },
    },
    {
      field: "price.usd",
      headerName: "Price (USD)",
      width: 200,
      align: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Typography variant="subtitle2">{params?.row?.price?.usd.toFixed(2)}</Typography>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getClBBidDetailsAPI(clbId));
  }, []);

  return (
    <Card
      className="transCard"
      sx={{
        my: 2,
        height: 420,
        width: "100%",
        "& .MuiDataGrid-row": {
          background: "#232323",
          color: "#fff",
          marginTop: "20px",
        },
      }}
    >
      <DataGrid
        sx={{ color: "#fff" }}
        getRowId={(row) => row._id}
        getRowHeight={() => "auto"}
        rows={result || []}
        columns={columns}
        pageSizeOptions={[25]}
        disableRowSelectionOnClick
        loading={loading && <CircularProgress />}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        slots={{
          noRowsOverlay: () => <NoDataFound />,
        }}
      />
    </Card>
  );
}
