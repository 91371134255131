import * as React from "react";
import ReactSlider from "../../common/ReactSlider";
import NextIcon from "../../assets/svg/nextIcon.svg";
import PrevIcon from "../../assets/svg/prevIcon.svg";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  Typography,
  Modal,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
};

export default function ImageModal({ handleClose, open, ele, imageArr }) {
  const [currentIndex, setCurrentIndex] = React.useState(ele);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageArr.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imageArr.length) % imageArr.length
    );
  };

  return (
    <div style={{ background: "transparent" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, backgroundColor: "black", position: "relative" }}>
          <IconButton
            onClick={handlePrevious}
            style={{
              position: "absolute",
              top: "40%",
              left: "4%",
              zIndex: 10,
            }}
          >
            <img className="nextIcon" src={PrevIcon} alt="nextIcon" />
            {/* <NextIcon /> */}
          </IconButton>
          <img
            src={imageArr[currentIndex]?.image}
            width={"100%"}
            height={"95%"}
          />
          <IconButton
            onClick={handleNext}
            style={{
              position: "absolute",
              top: "40%",
              right: "4%",
              zIndex: 10,
            }}
          >
            
            <img className="prevIcon" src={NextIcon} alt="prevIcon" />
            {/* <PrevIcon /> */}
          </IconButton>
          <Typography fontSize={12} sx={{ pt: 1 }} align="center">
            {imageArr[currentIndex]?.description}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
