import axios from "axios";

const getUsdInrCurrentRates = async () => {
  let res = await axios?.get(
    `https://openexchangerates.org/api/latest.json?app_id=f7e886874a7d4ab885688511c4357ac9&base=USD`
  );
  const priceCompData = res?.data;
  const USD = priceCompData?.rates?.USD;
  const INR = priceCompData?.rates?.INR;
  //   console.log("CONVERTORRRR: ", USD, INR);

  return {
    USD,
    INR,
  };
};

export default getUsdInrCurrentRates;
