import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HttpService from "../../config/authAxios"

const initialState = { loading: false, result: null, error: null, message: "" }

export const fetchPrivacyPolicy = createAsyncThunk(
    'get-privacy-policy',
    async (data, { rejectWithValue }) => {
        try {
            const response = await HttpService.get(`/resource/get-resource-details`)
            console.log(response.data)
            return await response.data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.response.data)
        }

    }
)

const policySlice = createSlice({
    name: 'policy',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchPrivacyPolicy.pending, (state, action) => {
                state.loading = true
            })
            .addCase(fetchPrivacyPolicy.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(fetchPrivacyPolicy.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const policyReducer = policySlice.reducer

export default policyReducer
