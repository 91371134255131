import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";

const initialState = { loading: false, result: null, error: null, message: "" };

export const getCurrentUserAPI = createAsyncThunk(
  "get-current-user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await HttpService.get(`/users/own`);
      localStorage.setItem("userId", response?.data?.result?._id);
      localStorage.setItem('userEmail', response?.data?.result?.email)
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "get-current-user",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCurrentUserAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(getCurrentUserAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const getCurrentUserReducer = counterSlice.reducer;

export default getCurrentUserReducer;
