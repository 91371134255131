import React, { useEffect } from "react";
import styles from "./terms.module.css";
import Footer from "../../../common/footer";
import Header from "../../header/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrivacyPolicy } from "../../../redux/privacyPolicy/privacy";

const Terms = () => {
  const dispatch = useDispatch();
  const termsConditions = useSelector(
    (state) => state?.policy?.result?.termsConditions
  );
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(fetchPrivacyPolicy());
  }, [dispatch]);
  return (
    <div>
      <Header />
      <div
        style={{
          background: `url(/images/background/Form.png) 100% 100% `,
          backgroundPosition: "center",
          backgroundSize: "100vw ",
        }}
      >
        <div className={styles.container}>
          <div className={styles.contained}>
            <br />
            <h1>Terms & Conditions</h1>
            <hr />
            <div style={{padding: '50px 80px', width: '100%', wordWrap: 'break-word', overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }} >
              {termsConditions?.length > 0
                ? termsConditions?.map((ele) => <p>{ele.points}</p>)
                : "No terms and conditions"}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default Terms;
