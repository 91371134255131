import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import HttpService from "../../src/config/authAxios";

export default function CoingateFail() {

  

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography fontSize={"20px"}>Payment Failed</Typography>
        </Box>
    )
}

