import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";
import { verifyOkycAPI } from "./verifyOkyc";

const initialState = { loading: false, result: null, error: null, message: "" };

export const initiateOkycAPI = createAsyncThunk(
  "initiate-okyc",
  async (data, { rejectWithValue }) => {
    try {
      const response = await HttpService.get(
        "/kyc/initiate/:refId".replace(":refId", data.refId)
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "initiate-okyc",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(initiateOkycAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(initiateOkycAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(initiateOkycAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      })
      .addCase(verifyOkycAPI.rejected, (state, action) => {
        if (
          action.payload?.message === "Invalid captcha entered" &&
          state.result.captchaRetriesRemaining !== 0
        )
          state.result.captchaRetriesRemaining =
            state.result.captchaRetriesRemaining - 1;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const initiateOkycReducer = counterSlice.reducer;

export default initiateOkycReducer;
