import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import HttpService from "../../src/config/authAxios";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSIngleCLBAPI } from "../redux/clb/getSIngleCLB";
import { placeOrderAPI } from "../redux/orders/placeOrder";

export default function CoingateSuccess() {

    
    const dispatch = useDispatch()
    const {id} = useParams()
    const navigate = useNavigate()

    const getOrder = async(result)=>{

        let id = localStorage.getItem("Coingate-orderId")
        try {
            const response = await HttpService.get(`payments/coingate-success/${id}`)
            console.log("response.payment_address------>",response.data.payment_address)
            if(response.data.status){
                dispatch(
                    placeOrderAPI({
                        sellerId: result?.creatorId,
                        clbId: result?._id,
                        transId: response.data.payment_address,
                        price: { inr: result?.cost?.inr, usd: result?.cost?.usd },
                        status: "Successfull",
                      })
                )
                navigate("/orders");
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{

        dispatch(getSIngleCLBAPI(id))
        .then((res)=>{
            getOrder(res.payload.result.CLBs);
        })

    },[])


    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography fontSize={"20px"}>Payment success</Typography>
        </Box>
    )
}



