import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";

const initialState = { loading: false, result: null, error: null, message: "" };

export const deleteCurrentUserOrgDocAPI = createAsyncThunk(
  "get-current-user-org",
  async (data, { rejectWithValue }) => {
    try {
      const response = await HttpService.delete(
        `/orgnisation/documents/${data.docId}`
      );
      //   localStorage.setItem("userId", response?.data?.result?._id);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "get-current-user-org",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(deleteCurrentUserOrgDocAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteCurrentUserOrgDocAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(deleteCurrentUserOrgDocAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const deleteCurrentUserOrgDocReducer = counterSlice.reducer;

export default deleteCurrentUserOrgDocReducer;
