import React from "react";
import styles from "./Aboutus.module.css";
const OurWork = (resource) => {
  return (
    <div className={styles.whowe}>
      <div style={{ textAlign: "justify", whiteSpace: 'pre-line'  }}>
        {resource?.resource?.anoutUs?.whatWeDo.desc}
      </div>
    </div>
  );
};
export default OurWork;
