import axios from "axios";
import { toast } from "react-hot-toast";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

// A set to track shown messages
const shownMessages = new Set();

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Check if the method is not 'get' and status is not 204
    if (response?.config?.method !== "get" && response.status !== 204) {
      // Check if the message has already been shown
      const message = response?.data?.message;
      if (message && !shownMessages.has(message)) {
        shownMessages.add(message); // Add the message to the set
        toast.success(message, {
          duration: 5000,
          position: "bottom-left",
        });
      }
      if(message ==="Multiple orders processed successfully."){
        setTimeout(()=>{
          window.location.reload();
        }, 200)
      }
    }
    return response;
  },
  function (error) {
    // Check if the method is not 'get'
    if (error?.response?.config?.method !== "get") {
      const message = error?.response?.data?.message;
      if (message && !shownMessages.has(message)) {
        shownMessages.add(message); // Add the message to the set
        toast.error(message, {
          duration: 5000,
          position: "bottom-left",
        });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
