import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/header/Header";
import { getAllCategoryAPI } from "../../redux/category/getAllCategory";
import { createCLBAPI } from "../../redux/clb/createCLB";
import { getAllCollectionAPI } from "../../redux/collection/getAllCollection";
import { getConfigMetaAPI } from "../../redux/config/getConfigMeta";
import { uploadFiles } from "../../service/upload";
import Style from "../../styles/clb.module.scss";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowRight from "@mui/icons-material/ArrowRight";
import BuyCLBConfimation from "../../dialog/BuyCLBConfimation";
import PayGasFees from "../../dialog/payGasFees";
import { getEstimatedGasFeesAPI } from "../../redux/clb/estimateGasPrice";
import toast from "react-hot-toast";
import HttpService from "../../config/authAxios";
import getUsdInrCurrentRates from "../../helper/usdInrPriceRates";

const schema = yup
  .object({
    title: yup.string().required("Title is required"),
    link: yup.string().required("Link is required"),
    description: yup.string().required("Description is required"),
    collection: yup.string().required("collection is required"),
    
  })
  .required();

let currencyRates;
const getCurrencyRates = async () => {
  currencyRates = await getUsdInrCurrentRates();
  return currencyRates;
};

getCurrencyRates();

const CreateCLB = () => {
  const getAllCategorySelector = useSelector((state) => state?.getAllCategory);
  const { result: categoryData } = getAllCategorySelector;

  const getAllCollectionSelector = useSelector(
    (state) => state?.getAllCollection
  );
  const { result: collection } = getAllCollectionSelector;

  const getEstimatedGasFeesSelector = useSelector(
    (state) => state?.getEstimatedGasFees
  );
  const { result: gasFees } = getEstimatedGasFeesSelector;

  const calculatedGasFees = (+gasFees?.UsdPrice * 3).toFixed(2);

  const {
    control,
    register,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm({ resolver: yupResolver(schema) });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "property",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const getFromLocalStorage = (key) => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  };

  const [imageLoading, setImageLoading] = useState(false);
  const [tempLoading, setTempLoading] = useState(false);
  const [clbAsset, setCLBAsset] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.clbAsset || [];
  });
  const [property, setProperty] = useState(() => {
    return (
      getFromLocalStorage("createCLBStates")?.property || { name: "", type: "" }
    );
  });
  const [expDate, setExpDate] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.expDate || null;
  });
  const [isBidAble, setIsBidAble] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.isBidAble || "Bid";
  });
  const [collect, setCollect] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.collect || null;
  });
  const [category, setCategory] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.category || null;
  });
  const [imageIpfs, setImageIpfs] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.imageIpfs || "";
  });
  const [cost, setCost] = useState(() => {
    return (
      getFromLocalStorage("createCLBStates")?.cost || { inr: null, usd: null }
    );
  });
  const [costComp, setCostComp] = useState(() => {
    return (
      getFromLocalStorage("createCLBStates")?.costComp || {
        inr: null,
        usd: null,
      }
    );
  });
  const [royaltyFee, setRoyaltyFee] = useState(() => {
    return (
      getFromLocalStorage("createCLBStates")?.royaltyFee || {
        percentage: null,
        INR: null,
        USD: null,
      }
    );
  });
  const [type, setType] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.type || null;
  });
  const [isGasFeesPaid, setIsGasFeesPaid] = useState(() => {
    return getFromLocalStorage("createCLBStates")?.isGasFeesPaid || false;
  });
  const [gasPayModalOpen, setGasPayModalOpen] = useState(false);
  const [gasFeeError, setGasFeeError] = useState("");

  useEffect(() => {
    const savedFormData = getFromLocalStorage("createCLBFormData");

    if (savedFormData) {
      for (const [key, value] of Object.entries(savedFormData)) {
        setValue(key, value);
      }
    }
  }, []);

  useEffect(() => {
    saveToLocalStorage("createCLBFormData", watch());
    saveToLocalStorage("createCLBStates", {
      clbAsset,
      property,
      expDate,
      isBidAble,
      collect,
      category,
      imageIpfs,
      cost,
      costComp,
      royaltyFee,
      type,
      isGasFeesPaid,
    });
  }, [
    watch(),
    clbAsset,
    property,
    expDate,
    isBidAble,
    collect,
    category,
    imageIpfs,
    cost,
    costComp,
    royaltyFee,
    type,
    isGasFeesPaid,
  ]);

  // const coingateOrderActionHandler = async () => { 
  //   try {
  //     const orderId = localStorage.getItem("Coingate-gas-priceId");
  //     const response = await HttpService.get(
  //       `/payments/coingate-success/${orderId}`
  //     );
  //     if (response?.data?.status === "paid") {
  //       toast.success("Payment successful!", {
  //         duration: 5000,
  //         position: "bottom-left",

  //       });
  //       setIsGasFeesPaid(true);
  //     } else if (
  //       response.data.status === "canceled" ||
  //       response.data.status === "expired" ||
  //       response.data.status === "invalid" 
  //     ) {
  //       toast.error("Payment failed or expired.", {
  //         duration: 5000,
  //         position: "bottom-left",
  //       });
  //       localStorage.removeItem("Coingate-gas-priceId");
  //     }
  //   } catch (error) {
  //     console.error("Error checking payment status:", error.message);
  //     localStorage.removeItem("Coingate-gas-priceId");
  //   }
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("Coingate-gas-priceId")) {
  //     coingateOrderActionHandler();
  //   }
  // }, []);
  const [toastShown, setToastShown] = useState({ success: false, error: false });



  const coingateOrderActionHandler = async () => {
    try {
      const orderId = localStorage.getItem("Coingate-gas-priceId");
      const response = await HttpService.get(`/payments/coingate-success/${orderId}`);
      if (response?.data?.status === "paid") {
        toast.success("Payment successful!", {
          duration: 5000,
          position: "bottom-left",
        });
        setIsGasFeesPaid(true);
      } else if (
        response.data.status === "canceled" ||
        response.data.status === "expired" ||
        response.data.status === "invalid"
      ) {
        toast.error("Payment failed or expired.", {
          duration: 5000,
          position: "bottom-left",
        });
        localStorage.removeItem("Coingate-gas-priceId");
      }
    } catch (error) {
      console.error("Error checking payment status:", error.message);
      localStorage.removeItem("Coingate-gas-priceId");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("Coingate-gas-priceId")) {
      coingateOrderActionHandler();
    }
  }, []);

  const autosetFileType = async (type) => {
    if (type.startsWith("image")) {
      setType("2d");
      return;
    }
    setType("3d");
  };
  const checkImageContent = async (imageData) => {
    const response = await axios.post(`https://vision.googleapis.com/v1/images:annotate?key=${process.env.REACT_APP_GOOGLE_API_KEY}`, {
      requests: [
        {
          image: { content: imageData },
          features: [{ type: 'SAFE_SEARCH_DETECTION' }]
        }
      ]
    });

    const safeSearchResult = response.data.responses[0].safeSearchAnnotation;
    return safeSearchResult.adult === 'VERY_LIKELY' || safeSearchResult.violence === 'VERY_LIKELY';
  };

  // google api only takes files in base64 format
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Return base64 without the data prefix
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

 
  const handleUploadCLB = async (event) => {
    setImageLoading(true);
    setCLBAsset([]);

    // Check if event and target are defined
    const { target } = event; // Destructure target from event

    if (!target || !target.files || target.files.length === 0) {
      toast.error('No file selected. Please choose an image to upload.');
      setImageLoading(false);
      return; // Early return if no file is selected
    }

    const file = target.files[0];

    if (!file) {
      toast.error('No file selected. Please choose an image to upload.');
      setImageLoading(false);
      return; // Early return if file is undefined
    }

    autosetFileType(file.type); // Proceed only if file is valid

    try {
      const base64Image = await fileToBase64(file);
      const isSensitive = await checkImageContent(base64Image);

      if (isSensitive) {
        toast.error('This image contains sensitive content and cannot be uploaded.');
        setImageLoading(false);
        return;
      }

      const ipfs = true;
      const data = await uploadFiles(file, ipfs);
      setImageIpfs(data?.ipfsFile);
      setCLBAsset([data?.fileUrl]);

      toast.success('Image uploaded successfully!');
    } catch (error) {
      console.error(error); // Log the error for debugging
      toast.error('An error occurred during the upload process.');
    } finally {
      setImageLoading(false);
    }
  };

  const handleCreateCLB = async (data) => {
    if (!isGasFeesPaid) {
      setGasFeeError("Gas fees must be paid before creating CLB.");
      return;
    }

    if (!clbAsset?.length) {
      return setError("fileError", {
        type: "custom",
        message: "Files can not be empty!",
      });
    }
    if (!category) {
      return setError("categoryError", {
        type: "custom",
        message: "Category can not be empty!",
      });
    }
    if (royaltyFee?.percentage > 99.9) {
      return setError("royaltyFeepercentageErr", {
        type: "custom",
        message: "Royaltyfee percentage can not be more then 99.9%!",
      });
    }
    if (!costComp?.inr) {
      return setError("cost.inr", {
        type: "custom",
        message: "Price (INR) can not be empty!",
      });
    }
    if (!costComp?.usd) {
      return setError("cost.usd", {
        type: "custom",
        message: "Price (USD) can not be empty!",
      });
    }

    if (!royaltyFee?.INR) {
      return setError("royaltyFeeINRErr", {
        type: "custom",
        message: "Royalty fee (INR) can not be empty!",
      });
    }
    if (!royaltyFee?.USD) {
      return setError("royaltyFeeUSDErr", {
        type: "custom",
        message: "Royalty fee (USD) can not be empty!",
      });
    }

    let isBidAbleType;
    if (isBidAble == "Bid") {
      if (!expDate) {
        return setError("expDateError", {
          type: "custom",
          message: "Expiry Date can not be empty!",
        });
      }
      isBidAbleType = true;
    } else {
      isBidAbleType = false;
      setExpDate(null);
    }

    const gasPrice = {
      inr: (
        Number(localStorage.getItem("Coingate-gas-price")) * currencyRates?.INR
      ).toFixed(2),
      usd: Number(localStorage.getItem("Coingate-gas-price")),
    };

    // check for nudity

    console.log("data", data)


    setTempLoading(true);

    const clb =  dispatch(
      createCLBAPI({
        isBidAble: isBidAbleType,
        collect: collect,
        property: fields,
        endDate: expDate,
        files: clbAsset,
        orderId: JSON.parse(localStorage.getItem("Coingate-gas-priceId")),
        category,
        gasPrice: gasPrice,
        cost: { inr: Number(costComp?.inr), usd: Number(costComp?.usd) },
        royaltyFee: {
          percentage: Number(royaltyFee?.percentage),
          INR: Number(royaltyFee?.INR),
          USD: Number(royaltyFee?.USD),
        },
        ipfsFile: imageIpfs,
        type,
        ...data,
      })
    );

    localStorage.removeItem("Coingate-gas-priceId");
    localStorage.removeItem("createCLBFormData");
    localStorage.removeItem("createCLBStates");
    localStorage.removeItem("Coingate-gas-price");

    setTempLoading(false);

    if (!clb?.payload?.error) {
      navigate("/explore");
    }
  };

  const handleAddProperty = () => {
    append({ name: property?.name, type: property?.type });
    setProperty({ name: "", type: "" });
  };
  const handleDeleteProperty = (idx) => remove(idx);

  const handlePriceCom = async () => {
    const { USD, INR } = await getUsdInrCurrentRates();
    setCostComp({ inr: cost?.inr, usd: (cost?.inr / INR).toFixed(2) });
  };

  const handleChangeRoyaltyFee = ({ target }) => {
    let percentageVal = target?.value;
    if (percentageVal < 0) {
      return;
    }
    setRoyaltyFee({
      percentage: percentageVal,
      INR: (costComp?.inr * percentageVal) / 100,
      USD: ((costComp?.usd * percentageVal) / 100).toFixed(2),
    });
  };

  useEffect(() => {
    dispatch(getAllCategoryAPI());
    dispatch(getAllCollectionAPI());
    dispatch(getConfigMetaAPI());
    dispatch(getEstimatedGasFeesAPI());
  }, []);

  useEffect(() => {
    handlePriceCom();
  }, [cost]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Box className={Style.main}>
        <Header />
        <Box className={Style.container}>
          <Grid container justifyContent="center" sx={{ p: 2 }}>
            <Grid lg={5} md={7} sm={9} xs={12}>
              <Card>
                <form onSubmit={handleSubmit(handleCreateCLB)}>
                  <Stack spacing={4}>
                    <Typography color="primary" variant="h5" align="center">
                      Create CLB
                    </Typography>
                    <FormControl>
                      <Box className="dropCard">
                        <input
                          style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          accept="image/*,.glb,.gltf"
                          onChange={handleUploadCLB}
                        />
                        <label htmlFor="raised-button-file">
                          <Stack alignItems="center" spacing={2} sx={{ my: 2 }}>
                            <Typography variant="h6">
                              Upload CLB asset!<sup>*</sup>
                            </Typography>
                            <ArrowCircleUpIcon
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "#999999",
                                borderRadius: 10,
                                p: 2,
                              }}
                            />
                            <Stack alignItems="center">
                              <Typography variant="subtitle2">
                                JPG, PNG, GIF, SVG
                              </Typography>
                              <Typography variant="subtitle2">
                                {" "}
                                GLB, GLTF.
                              </Typography>
                            </Stack>
                            <Typography variant="subtitle2">
                              Max size: 100 MB
                            </Typography>
                          </Stack>
                        </label>
                        <Grid container>
                          {imageLoading ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            <></>
                          )}
                          {clbAsset?.map((ele) => {
                            return (
                              <Box height={"50%"} className="CLBBox">
                                {(ele?.split(".")?.pop() == "jpg" ||
                                  ele?.split(".")?.pop() == "png" ||
                                  ele?.split(".")?.pop() == "svg" ||
                                  ele?.split(".")?.pop() == "gif") && (
                                    <img
                                      src={ele}
                                      width={"100%"}
                                      style={{ margin: "5px" }}
                                      alt="uploaded_img"
                                    />
                                  )}
                                {ele?.split(".")?.pop() == "glb" && (
                                  <model-viewer
                                    style={{
                                      width: "100%",
                                      height: 250,
                                      margin: "5px",
                                    }}
                                    src={ele}
                                    ar-modes="webxr scene-viewer quick-look"
                                    camera-controls
                                    poster="poster.webp"
                                    shadow-intensity="1"
                                    disable
                                  ></model-viewer>
                                )}
                              </Box>
                            );
                          })}
                        </Grid>
                      </Box>
                      <FormHelperText error>
                        {errors?.fileError?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField label="Title *" {...register("title")} />
                      <FormHelperText error>
                        {errors?.title?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        label="External Link *"
                        {...register("link")}
                      />
                      <FormHelperText error>
                        {errors?.link?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        multiline
                        rows={3}
                        label="Description *"
                        {...register("description")}
                      />
                      <FormHelperText error>
                        {errors?.description?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        label="Price (in INR) *"
                        value={cost?.inr}
                        onChange={({ target }) => {
                          if (target?.value < 0) {
                            return;
                          }
                          setCost({ ...cost, inr: target?.value })
                        }
                        }
                      />
                      <FormHelperText error>
                        {errors?.cost?.inr?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        label="Price (in USD)"
                        className="text-white-imp"
                        value={cost?.usd || costComp?.usd}
                        onChange={({ target }) =>
                          setCost({ ...cost, usd: target?.value })
                        }
                        disabled
                      />
                      <FormHelperText error>
                        {errors?.cost?.usd?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        className="text-white-imp"
                        label="Royalty Percentage *"
                        value={royaltyFee?.percentage}
                        onChange={handleChangeRoyaltyFee}
                        disabled={!costComp?.inr}
                      />
                      <FormHelperText error>
                        {errors?.royaltyFeepercentageErr?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        className="text-white-imp"
                        label="Royalty Fee(INR)"
                        value={royaltyFee?.INR}
                        disabled
                      />
                      <FormHelperText error>
                        {errors?.royaltyFeeINRErr?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="number"
                        className="text-white-imp"
                        label="Royalty Fee(USD)"
                        value={royaltyFee?.USD}
                        disabled
                      />
                      <FormHelperText error>
                        {errors?.royaltyFeeUSDErr?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <Autocomplete
                        disablePortal
                        options={categoryData}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, val) => {
                          setCategory(val?._id);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Category *" />
                        )}
                      />
                      <FormHelperText error>
                        {errors?.categoryError?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <Autocomplete
                        disablePortal
                        options={collection || []}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, val) => {
                          setCollect(val?._id);
                          clearErrors('collection')
                        }}
                        renderInput={(params) => (
                          <TextField {...register("collection")}  {...params} label="Collection *" />
                        )}
                      />
                       <FormHelperText error>
                        {errors?.collection?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <Autocomplete
                        className={Style.typeText}
                        disablePortal
                        options={["2d", "3d"]}
                        onChange={(event, val) => {
                          setType(val);
                        }}
                        value={type}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type"
                            {...register("clbType")}
                          />
                        )}

                      />
                      <FormHelperText error>
                        {errors?.clbType?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <RadioGroup
                        defaultValue={isBidAble}
                        onChange={({ target }) => setIsBidAble(target.value)}
                      >
                        <Stack direction="row" alignItems="center">
                          <FormControlLabel
                            sx={{ color: "#fff" }}
                            value="Bid"
                            control={<Radio />}
                            label="Bid"
                          />
                          <FormControlLabel
                            sx={{ color: "#fff" }}
                            value="Purchase"
                            control={<Radio />}
                            label="Purchase"
                          />
                        </Stack>
                      </RadioGroup>
                    </FormControl>

                    <Box sx={{ my: 2 }}>
                      <Typography variant="p" sx={{ color: "#fff" }}>
                        Properties
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ mt: 2 }}
                        alignItems="center"
                      >
                        <TextField
                          label="Name"
                          value={property?.name}
                          onChange={({ target }) =>
                            setProperty({ ...property, name: target?.value })
                          }
                        />
                        <TextField
                          label="Type"
                          value={property?.type}
                          onChange={({ target }) =>
                            setProperty({ ...property, type: target?.value })
                          }
                        />
                        <IconButton
                          disabled={!property?.name || !property?.type}
                          onClick={handleAddProperty}
                        >
                          <AddCircleIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Stack>

                      <Stack spacing={2} sx={{ mt: 2 }}>
                        {fields.map((field, idx) => {
                          return (
                            <>
                              <Stack
                                direction="row"
                                spacing={1}
                                key={idx}
                                alignItems="center"
                              >
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  label={field?.name}
                                  sx={{
                                    maxWidth: "200px",       // Set max width
                                    overflow: "hidden",      // Hide overflowed content
                                    textOverflow: "ellipsis", // Show ellipsis for overflowed text
                                    whiteSpace: "nowrap",    // Prevent text wrapping
                                  }}
                                />
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  label={field?.type}
                                  sx={{
                                    maxWidth: "200px",       // Set max width
                                    overflow: "hidden",      // Hide overflowed content
                                    textOverflow: "ellipsis", // Show ellipsis for overflowed text
                                    whiteSpace: "nowrap",    // Prevent text wrapping
                                  }}
                                />
                                <IconButton
                                  onClick={() => handleDeleteProperty(idx)}
                                >
                                  <DeleteIcon sx={{ color: "#fff" }} />
                                </IconButton>
                              </Stack>
                            </>
                          );
                        })}
                      </Stack>
                    </Box>

                    <FormControl>
                      {isBidAble == "Bid" ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            sx={{ bgcolor: "#484848", borderRadius: 2 }}
                            label="Expiry Date *"
                            slotProps={{
                              textField: { size: "small" },
                              rightArrowIcon: {
                                style: { color: "black" },
                              },
                              leftArrowIcon: {
                                style: { color: "black" },
                              },
                            }}
                            slots={{
                              leftArrowIcon: ArrowLeft,
                              rightArrowIcon: ArrowRight,
                            }}
                            minDate={new Date()}
                            onChange={(value) => {
                              setExpDate(moment(value).format("YYYY-MM-DD"));
                            }}
                          />
                        </LocalizationProvider>
                      ) : null}
                      <FormHelperText error>
                        {errors?.expDateError?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      {!isGasFeesPaid ? (
                        <Button
                          variant="contained"
                          onClick={() => setGasPayModalOpen(true)}
                        >
                          Pay Gas Fee (${calculatedGasFees})
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          style={{ background: "green", color: "#fff" }}
                        >
                          Paid Successfully
                        </Button>
                      )}
                    </FormControl>
                    {gasFeeError?.length !== 0 && (
                      <Typography color="error">{gasFeeError}</Typography>
                    )}
                    {
                      tempLoading ?
                        <CircularProgress /> :
                        <Button
                          disabled={!isGasFeesPaid}
                          type="submit"
                          variant="contained"
                        >
                          Create
                        </Button>
                    }
                  </Stack>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {gasPayModalOpen && (
        <PayGasFees
          open={gasPayModalOpen}
          setOpen={setGasPayModalOpen}
          gasPrice={calculatedGasFees}
        />
      )}
    </>
  );
};
export default CreateCLB;
