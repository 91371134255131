import Style from "./pastelCard.module.css";
import nextIcon from "../../assets/svg/next.svg";

const PastelCardComponent = ({ item, clickFun }) => {
  return (
    <div className={Style.container}>
      <div className={Style.imageTextContainer}>
        <div className={Style.imageBox}>
          <img
            src={item.cover}
            width={"100%"}
            height={"40px"}
            alt="coverImage"
            className={Style.mainImage}
          />
        </div>
        <div className={Style.textContainer}>
          <div className={Style.textTitle}>{item.name}</div>
          <div className={Style.textSubTitle}>by {item?.user?.name}</div>
          {/* <div className={Style.textPrice}>INR 5,093,832</div> */}

          <div className={Style.circleImageBox}>
            <img
              src={item.picture}
              alt="avatar"
              className={Style.circleImages}
            />
          </div>
        </div>
      </div>
      <div className={Style.nextButton} onClick={(e) => clickFun(e, item._id)}>
        <img src={nextIcon} alt="nextIcon" />
      </div>
    </div>
  );
};

export default PastelCardComponent;
