import {
  Avatar,
  Box,
  Stack,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const DisplayItem = ({ file, onRemove }) => {
  const name = file.originalname ? file.originalname : file.name;
  const truncatedFileName =
    name.length > 20 ? `${name.substring(0, 18)}...` : name;

  const handleDelete = async () => {
    if (file.originalname) {
      onRemove(file._id);
    } else {
      onRemove(file);
    }
  };

  return (
    <Stack flexDirection="row">
      <Box
        border={1}
        borderColor="grey.300"
        width="100%" // Set the width of the Box component
        p={1} // Add padding
        display="flex"
        alignItems="center"
        justifyContent="space-between" // Align items evenly
      >
        <ListItemAvatar>
          <Avatar sx={{ height: "100px", width: "100px" }}>
            <img src={file.link} alt={truncatedFileName} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText sx={{ padding: "5px" }}>
          <Typography variant="body1">{truncatedFileName}</Typography>
        </ListItemText>
        <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
          <DeleteIcon style={{ color: "red" }} fontSize="small" />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default DisplayItem;
