import { createSlice } from '@reduxjs/toolkit';

const initialState = { loading: false, result: null, error: null, message: "" }

const snackSlice = createSlice({
    name: 'snack-ui',
    initialState,
    reducers: {
        handleOpneSnack: (state, action) => {
            state.loading = false
            state.result = action.payload?.result
            state.message = action.payload?.message
        },
    },
});


const snackSliceReducer = snackSlice.reducer
export default snackSliceReducer
