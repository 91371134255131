import httpService from "../config/authAxios"

export async function CreateNft(payload) {
    const create = await httpService.post("/clbs/create", payload)
    return create
}
export async function getNfts(result, page, pageSize) {
    // console.log("page",  pageSize)
    const getAllNfts = await httpService.get("/clbs/approve?search=" + `${result}&pageNumber=${page ? page : ""}&pageSize=${pageSize ? pageSize : ""}`)
    return getAllNfts
}
export async function getSingleNft(id) {
    const singleNft = await httpService.get(`/clbs/${id}`)
    return singleNft
}

export function getFavClbs() {
    return httpService.get("/clbs/favs")
}
// export function 

export function likeClb(id) {
    return httpService.post("/favorites/clb/" + id)
}
export function filterApi(payload) {
    return httpService.post("/clbs/approve/filter", payload)
}

export function getSimilarClbs(clbId, search) {
    return httpService.get(`/clbs/${clbId}/similar/approve?search=` + `${search ? search : ""}`)
}
export function followUser(id) {
    return httpService.post("/follow/" + id)
}
export function statusChanged(id, payload) {
    return httpService.patch(`/clbs/active/change/${id}`, { active: payload })
}
export function createBid(id, payload) {
    return httpService.post(`/bids/${id}`, payload)
}
export function getAllBids(id) {
    return httpService.get(`/bids/getAllBid/${id}`)
}
export function getAllUserCreated(id) {
    return httpService.get(`/clbs/creator/${id}`)
}
export function getTopUsers() {
    return httpService.get("/clbs/topsellers")
}
export function getHotSelling(filter) {
    return httpService.get(`/clbs/hot-selling/?filter=${filter}`)
}