import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import { default as React } from "react";
import { useNavigate } from "react-router-dom";

export default function AuthMenu({ open, setOpen, anchorEl }) {
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { background: "#232323", color: "#fff" } }}
    >
      <Box sx={{ p: 2 }}>
        <List>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("/signIn");
            }}
          >
            <ListItemButton onClick={handleClose}>
              <ListItemIcon sx={{ color: "#fff" }}>
                <PersonRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            onClick={() => {
              navigate("/signUp");
            }}
          >
            <ListItemButton onClick={handleClose}>
              <ListItemIcon sx={{ color: "#fff" }}>
                <ExitToAppRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Up" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Menu>
  );
}
