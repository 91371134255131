import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";

const initialState = { loading: false, result: null, error: null, message: "" };

export const verifyOkycAPI = createAsyncThunk(
  "verify-okyc",
  async (data, { rejectWithValue }) => {
    const { refId, ...payload } = data;
    try {
      const response = await HttpService.post(
        "/kyc/verify/:refId".replace(":refId", refId),
        payload
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "verify-okyc",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(verifyOkycAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verifyOkycAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(verifyOkycAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const verifyOkycReducer = counterSlice.reducer;

export default verifyOkycReducer;
