import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";

const initialState = { loading: false, result: null, error: null, message: "" };

export const userFollowersCountAPI = createAsyncThunk(
  "user-followers-count",
  async (data, { rejectWithValue }) => {
    try {
      const response = await HttpService.get(`/follow/followers/count`, {
        params: data,
      });
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "user-followers-count",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(userFollowersCountAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userFollowersCountAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(userFollowersCountAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const userFollowersCountReducer = counterSlice.reducer;

export default userFollowersCountReducer;
