import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { extandEndDateAPI } from "../redux/clb/extandEndDate";
import Snack from "../ui/Snack";
import moment from "moment";
import { getSIngleCLBAPI } from "../redux/clb/getSIngleCLB";
import toast from "react-hot-toast";

export default function BuyCLBConfimation({ open, setOpen, CLB }) {
  const extandEndDateSelector = useSelector((state) => state?.extandEndDate);
  const { message, error } = extandEndDateSelector;

  const dispatch = useDispatch();

  const [endDate, setEndDate] = useState(null);

  const handleClose = () => setOpen(false);

  const handleExtantedExpDate = async () => {
    if (new Date(endDate) / 1 < new Date() / 1) {
      toast.error("Invalid date.");
      return;
    }
    await dispatch(extandEndDateAPI({ CLBId: CLB?._id, endDate: endDate }));
    await dispatch(getSIngleCLBAPI(CLB?._id));

    setOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} className="modal">
        <Box className="confirmeBox" sx={{ width: "30%" }}>
          <Stack direction="row" sx={{ my: 2 }}>
            <Typography align="center" variant="h5" sx={{ flex: 1 }}>
              Buy CLB !
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={{ bgcolor: "#484848", borderRadius: 2 }}
                  label="Expiry Date"
                  slotProps={{ textField: { size: "small" } }}
                  minDate={new Date()}
                  onChange={(value) => setEndDate(moment(value).format())}
                />
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button onClick={handleExtantedExpDate} variant="outlined">
                Extend
              </Button>
              <Button onClick={handleClose} variant="contained">
                Back
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
