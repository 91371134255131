import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import Header from "../../components/header/Header";
import { createCollectionAPI } from "../../redux/collection/createCollection";
import { uploadFiles } from "../../service/upload";
import Style from "../../styles/collection.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { getUserCollectionAPI } from "../../redux/collection/getUserCollection";

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    url: yup.string(),
    description: yup.string().required("Description is required"),
  })
  .required();

export default function CreateCollection() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [profileImage, setProfileImage] = useState({
    cover: null,
    picture: null,
  });

  const handleUploadPic = async ({ target }) => {
    const files = target.files?.[0];
    const data = await uploadFiles(files);
    setProfileImage({ ...profileImage, picture: data });
  };

  const handleUploadCover = async ({ target }) => {
    const files = target.files?.[0];
    const data = await uploadFiles(files);
    setProfileImage({ ...profileImage, cover: data });
  };

  const handleCreateCLB = async (data) => {
    if (!profileImage?.picture) {
      return setError("pictureErr", {
        type: "custom",
        message: "Picture can not be empty!",
      });
    }
    if (!profileImage?.cover) {
      return setError("coverErr", {
        type: "custom",
        message: "Cover Image can not be empty!",
      });
    }

    setLoading(true);

    dispatch(
      createCollectionAPI({
        ...data,
        cover: profileImage?.cover,
        picture: profileImage?.picture,
      })
    );
    dispatch(getUserCollectionAPI());
    reset();
    navigate("/collection");
    setLoading(false);
  };

  return (
    <Box className={Style.main}>
      <Header />
      <Box className={Style.container}>
        <Card sx={{ width: {xs: "100%", md: "40%"} }}>
          <form onSubmit={handleSubmit(handleCreateCLB)}>
            <Stack spacing={4}>
              <Typography color="primary" variant="h5" align="center">
                Create Collection
              </Typography>

              <FormControl>
                <Box className="dropCard">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleUploadPic}
                  />
                  <label htmlFor="raised-button-file">
                    <Stack alignItems="center" spacing={2} sx={{ my: 2 }}>
                      <Typography variant="h6">
                        Upload your collection profile picture!
                      </Typography>
                      <ArrowCircleUpIcon
                        sx={{
                          width: 80,
                          height: 80,
                          bgcolor: "#999999",
                          borderRadius: 10,
                          p: 2,
                        }}
                      />
                      <Stack alignItems="center">
                        <Typography variant="subtitle2">JPG</Typography>
                      </Stack>
                      <Typography variant="subtitle2">
                        Max size: 100 MB
                      </Typography>
                    </Stack>
                  </label>
                  {profileImage?.picture?.fileUrl ? (
                    <img
                      src={profileImage?.picture?.fileUrl}
                      alt="picture"
                      width={"100%"}
                      height={"200px"}
                    />
                  ) : null}
                </Box>
                <FormHelperText error>
                  {errors?.pictureErr?.message}
                </FormHelperText>
              </FormControl>

              <FormControl>
                <Box className="dropCard">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file-cover"
                    type="file"
                    onChange={handleUploadCover}
                  />
                  <label htmlFor="raised-button-file-cover">
                    <Stack alignItems="center" spacing={2} sx={{ my: 2 }}>
                      <Typography variant="h6">
                        Upload your collection profile cover!
                      </Typography>
                      <ArrowCircleUpIcon
                        sx={{
                          width: 80,
                          height: 80,
                          bgcolor: "#999999",
                          borderRadius: 10,
                          p: 2,
                        }}
                      />
                      <Stack alignItems="center">
                        <Typography variant="subtitle2">JPG</Typography>
                      </Stack>
                      <Typography variant="subtitle2">
                        Max size: 100 MB
                      </Typography>
                    </Stack>
                  </label>
                  {profileImage?.cover?.fileUrl ? (
                    <img
                      src={profileImage?.cover?.fileUrl}
                      alt="picture"
                      width={"100%"}
                      height={"200px"}
                    />
                  ) : null}
                </Box>
                <FormHelperText error>
                  {errors?.coverErr?.message}
                </FormHelperText>
              </FormControl>

              <FormControl>
                <TextField {...register("name")} label="Collection Name *" />
                <FormHelperText error>{errors?.name?.message}</FormHelperText>
              </FormControl>

              <FormControl>
                <TextField {...register("url")} label="Url" />
                <FormHelperText error>{errors?.url?.message}</FormHelperText>
              </FormControl>

              <FormControl>
                <TextField {...register("description")} label="Description *" />
                <FormHelperText error>
                  {errors?.description?.message}
                </FormHelperText>
              </FormControl>
              
              {
                loading ? 
                <CircularProgress /> :
                <Button type="submit" variant="contained">
                  Create
                </Button>
              }
            </Stack>
          </form>
        </Card>
      </Box>
    </Box>
  );
}
