import React from "react";
import styles from "./Aboutus.module.css";
const Whoarewe = (resource) => {
  console.log("🚀 ~ Whoarewe ~ resource:==>1", resource);

  return (
    <div className={styles.whowe}>
      {/* <p>{JSON.stringify(resource)}</p> */}
      <div style={{ textAlign: "justify", whiteSpace: 'pre-line' }}>
        {resource?.resource?.anoutUs?.whoWe.desc}
      </div>
    </div>
  );
};
export default Whoarewe;
