import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOkycAPI } from "../../redux/kyc/createOkyc";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";
import { initiateOkycAPI } from "../../redux/kyc/initiateOkyc";
import { verifyOkycAPI } from "../../redux/kyc/verifyOkyc";
import { completeOkycAPI } from "../../redux/kyc/completeOkyc";
import { resetStepState } from "../../redux/kyc/okycStep";
import { getCurrentUserAPI } from "../../redux/user/getCurrentUser";

const AddKyc = ({ open, setOpen }) => {
  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result } = getCurrentUserSelector;

  const okycStepReducer = useSelector((state) => state?.okycStepReducer);
  const { step } = okycStepReducer;

  const createOkycSelector = useSelector((state) => state?.createOkycReducer);
  const {
    loading: createOkycLoading,
    error: createOkycError,
    result: createOkycResult,
  } = createOkycSelector;

  const initiateOkycSelector = useSelector(
    (state) => state?.initiateOkycReducer
  );
  const {
    loading: initiateOkycLoading,
    error: initiateOkycError,
    message: initiateOkycMessage,
    result: initiateOkycResult,
  } = initiateOkycSelector;

  const verifyOkycSelector = useSelector((state) => state?.verifyOkycReducer);
  const {
    loading: verifyOkycLoading,
    error: verifyOkycError,
    result: verifyOkycResult,
  } = verifyOkycSelector;

  const dispatch = useDispatch();

  const [aadharNumber, setAadharNumber] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [otp, setOtp] = useState("");
  const [retry, setRetry] = useState(false);
  const [errors, setErrors] = useState({
    aadhar: "",
    captcha: "",
    otp: "",
  });

  const handleClose = () => {
    dispatch(getCurrentUserAPI());
    dispatch(resetStepState());
    setOpen(false);
  };

  const steps = [
    {
      id: "aadhar-number",
      label: "Enter AADHAAR Number",
    },
    {
      id: "verify",
      label: "Verify",
    },
    {
      id: "otp",
      label: "Enter OTP",
    },
    {
      id: "result",
      label: "Status",
    },
  ];

  const stepsUi = {
    "aadhar-number": () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        {createOkycLoading ? (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </span>
        ) : (
          <>
            <TextField
              fullWidth
              label="AADHAAR Number"
              onChange={(e) => {
                setErrors({ aadhar: "" });
                setAadharNumber(e.target.value);
              }}
            />
            <span
              style={{ fontSize: "10px", color: "red", paddingLeft: "2px" }}
            >
              {errors.aadhar}
            </span>
            <Button
              sx={{ marginTop: "10px" }}
              variant="contained"
              onClick={initiateOkyc}
            >
              Next
            </Button>
          </>
        )}
      </div>
    ),
    verify: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {initiateOkycLoading ? (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </span>
        ) : (
          <>
            {retry ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    color: "red",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Captcha Expired, Please retry
                </span>
                <Button
                  sx={{ marginTop: "10px" }}
                  variant="contained"
                  onClick={retryOkyc}
                >
                  Retry
                </Button>
              </div>
            ) : (
              <>
                <div>
                  <img
                    src={`data:image/png;base64,${initiateOkycResult.captchaImage}`}
                    alt="captcha"
                    style={{ width: "200px", background: "#ffffff" }}
                  />
                  <span style={{ fontSize: "8px", paddingLeft: "2px" }}>
                    Retries remaining:{" "}
                    {initiateOkycResult.captchaRetriesRemaining}
                  </span>
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="Enter captcha"
                    onChange={(e) => {
                      setErrors({ captcha: "" });
                      setCaptcha(e.target.value);
                    }}
                  />
                  <span
                    style={{
                      fontSize: "10px",
                      color: "red",
                      paddingLeft: "2px",
                    }}
                  >
                    {errors.captcha}
                  </span>
                  {initiateOkycError && (
                    <span
                      style={{
                        fontSize: "10px",
                        color: "red",
                        paddingLeft: "2px",
                      }}
                    >
                      {initiateOkycMessage}
                    </span>
                  )}
                </div>
                <Button
                  sx={{ marginTop: "10px" }}
                  variant="contained"
                  onClick={verifyOkyc}
                >
                  Next
                </Button>
              </>
            )}
          </>
        )}
      </div>
    ),
    otp: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        {verifyOkycLoading ? (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </span>
        ) : (
          <>
            <TextField
              fullWidth
              label="Enter OTP"
              onChange={(e) => {
                setErrors({ otp: "" });
                setOtp(e.target.value);
              }}
            />
            <span
              style={{ fontSize: "10px", color: "red", paddingLeft: "2px" }}
            >
              {errors.otp}
            </span>
            <Button
              sx={{ marginTop: "10px" }}
              variant="contained"
              onClick={completeOkyc}
            >
              Complete KYC
            </Button>
          </>
        )}
      </div>
    ),
    result: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        {createOkycLoading ? (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </span>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              OKYC Verified.
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <VerifiedTwoToneIcon
                sx={{ height: "200px", width: "200px", color: "#35ABAE" }}
              />
            </span>
            <Button
              sx={{ marginTop: "10px" }}
              variant="contained"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        )}
      </div>
    ),
  };

  const validateAadhar = () => {
    if (aadharNumber.length < 12 || aadharNumber.length > 12) {
      setErrors({ ...errors, aadhar: "Invalid AADHAAR number" });
      return false;
    }
    return true;
  };

  const validateCaptcha = () => {
    if (captcha === "") {
      setErrors({ ...errors, captcha: "Invalid captcha" });
      return false;
    }
    return true;
  };

  const validateOtp = () => {
    if (otp === "") {
      setErrors({ ...errors, otp: "Invalid OTP" });
      return false;
    }
    return true;
  };

  const initiateOkyc = async () => {
    if (!validateAadhar()) {
      return;
    }
    await dispatch(initiateOkycAPI({ refId: createOkycResult["id"] }));
  };

  const verifyOkyc = async () => {
    if (!validateCaptcha()) {
      return;
    }
    await dispatch(
      verifyOkycAPI({
        refId: createOkycResult["id"],
        aadhaarNumber: aadharNumber,
        captchaCode: captcha,
      })
    );
  };

  const completeOkyc = async () => {
    if (!validateOtp()) {
      return;
    }
    await dispatch(
      completeOkycAPI({
        refId: createOkycResult["id"],
        otp,
        aadhaarNumber: aadharNumber,
        shareCode: "1234",
      })
    );
  };

  const retryOkyc = async () => {
    await dispatch(resetStepState());
    await dispatch(createOkycAPI());
    setRetry(false);
  };

  useEffect(() => {
    if (initiateOkycResult?.captchaRetriesRemaining === 0) setRetry(true);
  }, [initiateOkycResult]);

  return (
    <>
      <Modal open={open} onClose={handleClose} className="modal">
        <Card sx={{ width: "80%" }}>
          <Stack direction="row" sx={{ my: 2 }} justifyContent="space-between">
            <Typography sx={{ flex: 1 }} align="center" variant="h5">
              Add KYC
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stepper activeStep={step} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.id}>
                <StepLabel color="#ffffff">
                  <span style={{ color: "#ffffff" }}>{step.label}</span>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <>{stepsUi[steps[step]["id"]]()}</>
        </Card>
      </Modal>
    </>
  );
};

export default AddKyc;
