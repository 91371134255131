import { yupResolver } from "@hookform/resolvers/yup";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/header/Header";
import { raiseTicketAPI } from "../../redux/ticket/raiseTicket";
import { uploadFiles } from "../../service/upload";
import Style from "../../styles/clb.module.scss";

const schema = yup
  .object({
    legalName: yup
      .string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid legal name")
      .required("Name is required"),
    registrationNo: yup
      .string()
      .matches(/^[0-9]+$/, "Registration number should have only number digits")
      .required("required field"),
    address: yup.string().required("Registration number is required"),
    SLBRequired: yup
      .number()
      .positive()
      .typeError("SLBs should have only number digits")
      .required("Number of slbs is required"),
  })
  .required();

export default function RaiseTicket() {
  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dateInCorp, setDateInCorp] = useState(null);
  const [identifyProof, setIdentifyProof] = useState("");
  const [addressProof, setAddressProof] = useState("");

  const handleUploadIdentifyProof = async ({ target }) => {
    const files = target.files?.[0];
    const data = await uploadFiles(files);
    setIdentifyProof(data);
  };

  const handleUploadAddressProof = async ({ target }) => {
    const files = target.files?.[0];
    const data = await uploadFiles(files);
    setAddressProof(data);
  };

  const handleCreateCLB = async (data) => {
    if (!identifyProof) {
      return setError("identifyProofError", {
        type: "custom",
        message: "IdentifyProof can not be empty!",
      });
    }

    if (!addressProof) {
      return setError("addressProofError", {
        type: "custom",
        message: "AddressProofError can not be empty!",
      });
    }

    // reset();
    await dispatch(
      raiseTicketAPI({
        ...data,
        identifyProof,
        addressProof,
        dateInCorp,
      })
    );
    reset();
    window.location.href = "/";
  };

  return (
    <>
      <Box className={Style.main}>
        <Header />
        <Box className={Style.container}>
          <Grid container justifyContent="center" sx={{ p: 2 }}>
            <Grid lg={5} md={7} sm={9} xs={12}>
              <Card>
                <form onSubmit={handleSubmit(handleCreateCLB)}>
                  <Stack spacing={4}>
                    <Stack alignItems="center" spacing={1}>
                      <Typography color="primary" variant="h5">
                        Raise Ticket
                      </Typography>
                    </Stack>
                    <FormControl>
                      <Box className="dropCard">
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          onChange={handleUploadIdentifyProof}
                        />
                        <label htmlFor="raised-button-file">
                          <Stack alignItems="center" spacing={2} sx={{ my: 2 }}>
                            <Typography variant="h6">
                              IdentifyProof Image
                            </Typography>
                            <ArrowCircleUpIcon
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "#999999",
                                borderRadius: 10,
                                p: 2,
                              }}
                            />
                            <Stack alignItems="center">
                              <Typography variant="subtitle2">
                                JPG, PNG
                              </Typography>
                            </Stack>
                            <Typography variant="subtitle2">
                              Max size: 5 MB
                            </Typography>
                          </Stack>
                        </label>
                        {identifyProof && (
                          <img
                            src={identifyProof.fileUrl}
                            alt="picture"
                            width={"100%"}
                            height={"auto"}
                          />
                        )}
                      </Box>
                      <FormHelperText error>
                        {errors?.identifyProofError?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <Box className="dropCard">
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file-addressProof"
                          type="file"
                          onChange={handleUploadAddressProof}
                        />
                        <label htmlFor="raised-button-file-addressProof">
                          <Stack alignItems="center" spacing={2} sx={{ my: 2 }}>
                            <Typography variant="h6">
                              AddressProof Image
                            </Typography>
                            <ArrowCircleUpIcon
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "#999999",
                                borderRadius: 10,
                                p: 2,
                              }}
                            />
                            <Stack alignItems="center">
                              <Typography variant="subtitle2">
                                JPG, PNG
                              </Typography>
                            </Stack>
                            <Typography variant="subtitle2">
                              Max size: 5 MB
                            </Typography>
                          </Stack>
                        </label>
                        {addressProof && (
                          <img
                            src={addressProof.fileUrl}
                            alt="picture"
                            width={"100%"}
                            height={"auto"}
                          />
                        )}
                      </Box>
                      <FormHelperText error>
                        {errors?.addressProofError?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        label="Legal Name"
                        {...register("legalName")}
                      />
                      <FormHelperText error>
                        {errors?.legalName?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField
                        label="Registration No"
                        {...register("registrationNo")}
                      />
                      <FormHelperText error>
                        {errors?.registrationNo?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl>
                      <TextField label="Address" {...register("address")} />
                      <FormHelperText error>
                        {errors?.address?.message}
                      </FormHelperText>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        sx={{ bgcolor: "#484848", borderRadius: 2 }}
                        label="Date Incorpration"
                        onChange={(e, value) =>
                          setDateInCorp(moment(value).format("YYYY-MM-DD"))
                        }
                      />
                    </LocalizationProvider>

                    <FormControl>
                      <TextField
                        type="number"
                        label="SLBs Required"
                        {...register("SLBRequired")}
                      />
                      <FormHelperText error>
                        {errors?.SLBRequired?.message}
                      </FormHelperText>
                    </FormControl>

                    <Button type="submit" variant="contained">
                      Raise Now
                    </Button>
                  </Stack>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
